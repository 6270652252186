import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
        <span style={{ fontWeight: 'normal' }}>Hi, I'm </span>Noah Schill
        </h1>
        <h3><span style={{ fontWeight: 'normal' }}>a </span> Full-Stack Software and Analytics Engineer</h3>

        <p>
         I develop scalable and agile analytics and software solutions for complex systems and processes. 
        </p>
        <p>
         I'm proficient in a wide range of technologies including Python, SQL, TensorFlow, JavaScript, Linux, Kubernetes, Kafka, and all tools necessary to build data integration, warehousing, and machine learning solutions in the cloud or on-premise.
        </p>
        <p>
          I currently work in semiconductor industry -- integrating data across the wafer manufacturing process to unveil defects and process bottlenecks. Let's connect!
        </p>

        <a
          className="App-link"
          href="https://github.com/nrsmac"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/noah-schill/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>

      </header>
    </div>
  );
}

export default App;
